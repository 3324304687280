


























































































































































































































































































































































.jiaoYiRadioStyle {
	padding-left: 0.2rem;
}

.chongZhiStyle {
	width: 3.4rem;
}

.queDingStyle {
	background: #90C4FF;
	border: 0rem;
	width: 3.4rem;
}

.tanchuInputStyle {
	padding: 0.1rem;
	background: #f1f1f3;
	border: 0rem;
	border-radius: 5px;
}

.inputStyles {
	width: 100%;
}

.pNieStyle {
	margin-top: 0.1rem;
	padding-left: 0.2rem;
	font-size: 0.1rem;
	color: #948690;
}

.van-field {
	padding: 0.1rem;
	width: 3.5rem;
}

.vanFiedlStyle {
	border-radius: 5px;
	background: #f1f1f3;
	// height: 0.5rem;
}

// .radioStyle{
//   background: red;
// }
.dingDanStyle2 {
	margin: 0.1rem;
	color: #ecbb34;
}

.dingDanStyle {
	margin: 0.3rem;
	color: #999999;
}

.tanChuCengDivStyle1 {
	text-align: center;
	background: #90C4FF;
	border-radius: 5px;
	padding: 0.17rem;
	color: #ffffff;
}

.tanChuCengDivStyle {
	text-align: center;
	background: #f1f1f3;
	border-radius: 5px;
	padding: 0.17rem;
	// color: #ffffff;
}

.tanChuCeng {
	padding: 0.3rem;
}

.titleBarStyle {
	position: absolute;
	top: 0rem;
	// margin-bottom: 1rem;
	width: 100%;
}

.showOrHiden {
	// margin: 0 auto;
	position: absolute;
	top: 0.9rem;
	border-radius: 7px;
	width: 92%;
	margin-left: 0.3rem;
	// margin-right: 1.3rem;
}

.buyInTo {
	color: green;
	padding-right: 0.2rem;
}

.firstLiStyle {
	font-size: .28rem;
	background: #F5F5F5;
	border-radius: .12rem;
	margin-bottom: 0.25rem;
}

.spanOfdiv {
	margin-bottom: 0.1rem;
	height: 1rem;
	width: 1rem;
	background: #f3f0f3;
	border-radius: 50px;
}

.ulStyleOfTrans {
	margin-left: 1rem;
	// margin-right: 1rem;
}

.spanStyleInTransac {
	margin-top: 0.2rem;
}

.divBorderStyle {
	width: 1.2rem;
	height: 1.2rem;
	background: red;
	border-radius: 50px;
}

.bodySpanStyle {
	color: #ff4b04;
}

.bodyLiStyle {
	margin: 0.1rem;
	color: #86878d;
	font-size: 15px;
}

.bodyDivStyle {
	border-radius: 5px;
	background: #ffffff;
	margin: 0.3rem;
	padding: 0.2rem;
}

.titlePStlye {
	margin-top: 0.3rem;
	padding-left: 0.1rem;
	font-size: 0.2rem;
	color: #ffffff;
}

.vanBodyCol {
	border-radius: 5px;
	height: 1.7rem;
	margin: 0.2rem;
	border: 1px solid #999999;
	background: #ecbb34;
}

.bodyPStyle {
	margin-top: 0.2rem;
	font-size: 0.4rem;
	color: #ffffff;
	padding-left: 0.1rem;
}

// .bodyVanRow {
//   text-align: center;
// }
.titleVanRowDivStyle {
	width: 100%;
	padding: 5px;
	background: #ffffff;
}

.titleVanStyle {
	background: red;
	height: 2rem;
	border-radius: 5px;
}

s .colorStyle {
	color: #999999;
}

.btnFdivStyle {
	text-align: center;
}

.btnStyle {
	margin-top: 1rem;
	width: 4.2rem;
}

.inputStyle {
	border-top: 0;
	border-left: 0;
	border-right: 0;
	width: 100%;
	border-bottom: 1px solid #f2f2f2;
	margin-top: 0.1rem;
	margin-bottom: 0.1rem;
	color: #8b7575;
	padding: 0.1rem;
}

.fontStyle {
	font-size: 0.1rem;
	color: #9b9b9b;
	margin: 0.3rem;
}

.iconStyle {
	margin-right: 0.15rem;
}

.ulList > li {
	padding: 0.3rem;
	margin: 0.3rem;
	background: #ffffff;
	border-radius: 5px;
}

.listStyle {
	background: #ffffff;
	border-radius: 5px;
}

.roleBtnStyle {
	margin-left: 0.4rem;
	margin-right: 0.4rem;
	border-radius: 5px;
}

.cashflow {
	// 我发出的
	// width: 100%;
	// background: #f3f0f3;
	// height: 100%;
	// overflow-y: auto;
	font-family: PingFangSC-Regular;

	.cash {
		padding: 0.3rem 0.5rem;
		border-bottom: 1px solid var(--bdColor);

		.cash_text {
			.g {
				color: #999;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}
		}

		.cash_text2 {
			width: 3.2rem;
			text-align: center;
			margin: 0 auto;
			height: 0.74rem;
			line-height: 0.74rem;
			font-size: 0.26rem;
			margin-top: 0.4rem;
			border-radius: 2.5px;
			color: #ffffff;
			background: #2f83ff;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;